<template>
  <aw-tabler :table="tableJSON">
    <template #filter>
      <el-form :model="tableJSON.filter" label-suffix="：" inline>
        <el-form-item label="ID" prop="id">
          <el-input v-model.trim="tableJSON.filter.id" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="代理商名称" prop="name">
          <el-input v-model.trim="tableJSON.filter.name" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="手机号" prop="username">
          <el-input v-model.trim="tableJSON.filter.username" placeholder="请输入" />
        </el-form-item>
      </el-form>
    </template>
    <template #drawercontent>
      <el-form
        v-loading="formLoading"
        class="add-form"
        ref="agentFormEle"
        style="padding-right: 10px;"
        :model="agentForm"
        :rules="agentRules"
        label-width="120px"
        :disabled="agentFormType === 'show'"
      >
        <h4 class="tit">基本信息</h4>
        <el-form-item label="代理商名称" prop="name">
          <el-input v-model.trim="agentForm.name" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="合作类型" prop="with_type_id">
          <el-select
            v-model="agentForm.with_type_id"
            placeholder="请选择"
            filterable
          >
            <template v-for="opt in cooperationTypeOpt" :key="opt.id">
              <el-option
                :label="opt.name"
                :value="opt.id"
              />
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号" prop="username">
          <el-input v-model.trim="agentForm.username" placeholder="请输入" />
        </el-form-item>
        <template v-if="agentFormType !== 'show'">
          <el-form-item label="登录密码" prop="password">
            <el-input v-model.trim="agentForm.password" type="password" show-password placeholder="请输入登录密码" />
          </el-form-item>
          <el-form-item label="确认密码" prop="confirm_password">
            <el-input v-model.trim="agentForm.confirm_password" type="password" show-password placeholder="请确认登录密码" />
          </el-form-item>
        </template>
        
        <el-form-item label="真实姓名" prop="truename">
          <el-input v-model.trim="agentForm.truename" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="收货地址" prop="area_code">
          <el-cascader
            v-model="agentForm.area_code"
            :options="areaCode"
            :props="provinceProp"
          />
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input v-model.trim="agentForm.address" type="textarea" placeholder="请输入" maxlength="120" show-word-limit />
        </el-form-item>
        <el-form-item label="是否有推荐人" prop="is_push">
          <el-radio-group v-model="agentForm.is_push">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="-1">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="agentForm.is_push === 1">
          <el-form-item
            label="推荐人"
            prop="push_agent_id"
            :rules="[{ required: true, message: '请选择推荐人' }]"
          >
            <el-select v-model="agentForm.push_agent_id" placeholder="请选择" filterable>
              <template v-for="opt in recommendOpt">
                <el-option
                  v-if="opt.id !== agentForm.id"
                  :key="opt.id"
                  :label="opt.name"
                  :value="opt.id"
                />
              </template>
            </el-select>
          </el-form-item>
          <el-form-item
            label="推荐人赠品"
            prop="push_agent_gift_id"
            :rules="[{ required: true, message: '请选择推荐人赠品' }]"
          >
            <el-select v-model="agentForm.push_agent_gift_id" placeholder="请选择" filterable>
              <el-option
                v-for="opt in recommendRewardOpt"
                :key="opt.id"
                :label="opt.name"
                :value="opt.id"
              />
            </el-select>
          </el-form-item>
        </template>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="agentForm.status">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="-1">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <h4 class="tit">商品信息</h4>
        <el-form-item label="商品定价">
          <div class="goods-info">
            <div class="btn-line">
              <el-button type="primary" @click="handlerAddGoods">添加商品</el-button>
<!--              <span>列表不存在的商品，用户不显示。</span>-->
            </div>
            <el-table
              v-if="agentForm.goods_price.length > 0"
              :data="agentForm.goods_price"
              style="width: 100%"
              border
            >
              <el-table-column align="center" header-align="center" minWidth="180px">
                <template #header>
                  <div class="info-list-hd">
                    <span class="red">*</span>
                    <span>商品</span>
                  </div>
                </template>
                <template #default="{row}">
                  <el-select
                    v-model="row.goods_id"
                    placeholder="请选择"
                    filterable
                  >
                    <el-option
                      v-for="opt in goodsOpt"
                      :label="opt.name"
                      :value="opt.id"
                      :key="opt.id"
                      :disabled="!!agentForm.goods_price.find(g => g.goods_id === opt.id)"
                    />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column align="center" header-align="center" minWidth="160px">
                <template #header>
                  <div class="info-list-hd">
                    <span class="red">*</span>
                    <span>销售价（元/件）</span>
                  </div>
                </template>
                <template #default="{row}">
                  <el-input-number
                    v-model="row.price"
                    placeholder="请输入"
                    controls-position="right"
                    :precision="2"
                    :min="0.01"
                    :disabled="!row.goods_id"
                  />
                </template>
              </el-table-column>
              <el-table-column align="center" header-align="center" minWidth="160px">
                <template #header>
                  <div class="info-list-hd">
                    <span class="red">*</span>
                    <span>采购价（元/件）</span>
                  </div>
                </template>
                <template #default="{row}">
                  <el-input-number
                    v-model="row.cost_price"
                    placeholder="请输入"
                    controls-position="right"
                    :precision="2"
                    :max="row.price"
                    :min="0"
                    :disabled="!row.goods_id || !row.price"
                  />
                </template>
              </el-table-column>
              <el-table-column align="center" header-align="center" minWidth="170px">
                <template #header>
                  <div class="info-list-hd">
                    <span class="red">*</span>
                    <span>推荐人分佣（元/件）</span>
                  </div>
                </template>
                <template #default="{row}">
                  <el-input-number
                    v-model="row.fee_price"
                    placeholder="请输入"
                    controls-position="right"
                    :precision="2"
                    :min="0"
                    :max="row.price - row.cost_price"
                    :disabled="!row.goods_id || !row.price"
                  />
                </template>
              </el-table-column>
<!--              <el-table-column label="现库存" prop="kucun">213</el-table-column>-->
<!--              <el-table-column label="支付方式" min-width="220">-->
<!--                <template #header>-->
<!--                  <div class="info-list-hd">-->
<!--                    <span class="red">*</span>-->
<!--                    <span>支付方式</span>-->
<!--                  </div>-->
<!--                </template>-->
<!--                <el-checkbox-group>-->
<!--                  <el-checkbox>微信</el-checkbox>-->
<!--                  <el-checkbox>线下付款</el-checkbox>-->
<!--                </el-checkbox-group>-->
<!--              </el-table-column>-->
<!--              <el-table-column label="显示/隐藏" min-width="90">-->
<!--                <el-switch />-->
<!--              </el-table-column>-->
              <el-table-column
                  align="center"
                  header-align="center"
                  fixed="right"
                  width="100px"
              >
                <template #header>
                  <div class="info-list-hd">
                    <span>操作</span>
                  </div>
                </template>
                <template #default="{$index}">
                  <el-button
                    type="danger"
                    text
                    @click="handlerDeleteGoods($index)"
                  >删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
        
        <WithDraw
          v-if="(agentForm.cash_type && agentForm.cash_user_id) || agentFormType !== 'show'"
          v-model:info="agentForm"
        />
        
        <el-form-item>
          <el-button type="primary" :loading="submitLoading" @click="handlerStoreGoods">保存</el-button>
          <el-button @click="cancelDrawer">取消</el-button>
        </el-form-item>
      </el-form>
    </template>
  </aw-tabler>
</template>

<script setup>
import {reactive, ref} from 'vue'
import { tableColumn } from '@/assets/js/extend/tableColumn'
import {http, tool} from "@/assets/js"
import {useRoute} from 'vue-router'
import areaCode from '@/assets/json/areacode.json'
import {ElMessage} from 'element-plus'
import WithDraw from './components/withdraw.vue'

const agentFormEle = ref()
const agentFormType = ref('')
const agentForm = ref({
  name: '',
  with_type_id: '',
  username: '',
  password: '',
  confirm_password: '',
  truename: '',
  area_code: '',
  address: '',
  is_push: 1,
  push_agent_id: '',
  push_agent_gift_id: '',
  status: 1,
  goods_price: [
    {
      goods_id: '',
      price: 0,
      cost_price: 0,
      fee_price: 0
    }
  ],
  cash_type: 1, // 提现方式 1微信
  freeze_day: 1, // 冻结期限
  cash_user_type: 1, // 提现账户 1商户号，2个人微信
  cash_user_id: '', // 商户号、用户id
})

const validPsd2 = (rule, value, callback) => {
  if(value === '') {
    callback(new Error('请输入'))
  } else if(value !== agentForm.value.password) {
    callback(new Error('确认密码与密码不一致'))
  } else callback()
}
const agentRules = ref({
  name: [
    { required: true, message: '请输入代理商名称' }
  ],
  with_type_id: [
    { required: true, message: '请选择合作类型' }
  ],
  username: [
    { required: true, message: '请输入手机号' },
    { pattern: /^((13[0-9])|(14[5|7])|(15[0-3|5-9])|(17[0|1|3|5-8])|(18[0-9])|166|198|199)\d{8}$/, message: '手机号格式不正确', trigger: 'blur' }
  ],
  password: [
    { min: 6, message: '密码最少6位', trigger: 'blur' },
    { max: 13, message: '密码最多13位', trigger: 'blur' }
  ],
  confirm_password: [
    { validator: validPsd2, trigger: 'blur' }
  ],
  area_code: [
    { required: true, message: '请选择地区' }
  ],
  address: [
    { required: true, message: '请输入详细地址' }
  ],
  is_push: [
    { required: true, message: '请选择' }
  ],
  status: [
    { required: true, message: '请选择' }
  ]
})
// 合作类型
const cooperationTypeOpt = ref([])
const getCooperationOpt = () => {
  if(cooperationTypeOpt.value.length === 0) {
    if(powerlimits.includes('dlslb_hzlx')){
      http.post('/admin/agent/withTypeIndex', {
        page: 1,
        pageSize: 9999
      }).then(res => {
        cooperationTypeOpt.value = res.data
      })
    }
  }
}
// 可选商品
const goodsOpt = ref([])
const getGoodsOpt = () => {
  if(goodsOpt.value.length === 0) {
    if(powerlimits.includes('dlslb_sp')) {
      http.post('/admin/goods/index',{
        page: 1,
        pageSize: 9999
      }).then(res => {
        goodsOpt.value = res.data
      })
    }
  }
}

// 推荐人
const recommendOpt = ref([])
const getRecommendOpt = () => {
  if(recommendOpt.value.length === 0) {
    http.post('/admin/agent/index', {
      page: 1,
      pageSize: 9999
    }).then(res => {
      recommendOpt.value = res.data
    })
  }
}
// 推荐人奖励
const recommendRewardOpt = ref([])
const getRecommendRewardOpt = () => {
  if(recommendRewardOpt.value.length === 0) {
    if(powerlimits.includes('dlslb_tjrjl')) {
      http.post('/admin/agent/pushGiftIndex', {
        page: 1,
        pageSize: 9999
      }).then(res => {
        recommendRewardOpt.value = res.data
      })
    }
  }
}

const getOptData = () => {
  getCooperationOpt()
  getGoodsOpt()
  getRecommendOpt()
  getRecommendRewardOpt()
}

// 省市区级联prop
const provinceProp = ref({
  emitPath: false,
  value: 'value',
  label: 'label'
})

const route = useRoute()
const powerlimits = reactive(tool.getArrayToOneChat(route.meta.roles,'component'))

const tableJSON = ref({
  title: '代理商列表',
  loading: false,
  filter: {
    id: '',
    name: '',
    username: ''
  },
  datas: [],
  columns: [
      ...tableColumn([
        {
          title: 'ID',
          key: 'id',
          width: '100px'
        },
        {
          title: '代理商名称',
          key: 'name',
          minWidth: '200px'
        },
        {
          title: '邀请码',
          key: 'pull_code',
          minWidth: '100px'
        },
        {
          title: '合作类型',
          key: 'with_type_name',
          minWidth: '140px'
        },
        {
          title: '手机号',
          key: 'username',
          minWidth: '160px'
        },
        {
          title: '推荐人',
          key: 'push_agent_name',
          minWidth: '140px'
        },
        {
          title: '推荐人奖励',
          key: 'push_agent_gift_name',
          minWidth: '180px'
        },
        // {
        //   title: '公众号授权',
        //   key: 'wechatAuth',
        //   minWidth: '180px'
        // },
        {
          title: '状态',
          key: 'status',
          width: '80px',
          confirm: true,
          props: {disabled: !powerlimits.includes('dlslb_bj')},
          switch: (data) => {
            http.post('/admin/agent/switch', {id: data.id})
          }
        },
        // {
        //   title: '操作时间',
        //   key: 'addtime',
        //   minWidth: '140px'
        // },
        // {
        //   title: '操作人',
        //   key: '',
        //   minWidth: '120px'
        // }
      ]),
    {
      fixed: 'right',
      title: '操作',
      key: '',
      width: '230px',
      buttons: [
        {
          name: '查看',
          props: {type: 'primary'},
          show: () => powerlimits.includes('dlslb_ck'),
          action: (data) => {
            agentFormType.value = 'show'
            tableJSON.value.drawer.title = '代理商详情'
            tableJSON.value.drawer.show = true
            
            getAgentDetail(data.id)
            getOptData()
          }
        },
        {
          name: '编辑',
          props: {type: 'primary',},
          show: () => powerlimits.includes('dlslb_bj'),
          action: (data) => {
            agentFormType.value = 'edit'
            tableJSON.value.drawer.title = '修改代理商'
            tableJSON.value.drawer.show = true
            
            getAgentDetail(data.id)
            getOptData()
          }
        },
        // {
        //   name: '登录',
        //   props: {type: 'primary',},
        //   show: () => powerlimits.includes('dlslb_dl'),
        //   action: (data) => {
        //     window.open('http://mall.agent.jingshuoprint.net', '__blank')
        //   }
        // }
      ]
    }
  ],
  page: {
    total: 0,
    page: 1,
    page_size: 10,
    sizeChange: (val) => {
      tableJSON.value.page.page_size = val
      tableJSON.value.page.page = 1
      tableJSON.value.action(tableJSON.value.filter)
    },
    currentChange: (val) => {
      tableJSON.value.page.page = val
      tableJSON.value.action(tableJSON.value.filter)
    }
  },
  action: async (params) => {
    let page = { page: tableJSON.value.page.page, page_size: tableJSON.value.page.page_size }
    if(powerlimits.includes('dlslb_lb')) {
      tableJSON.value.loading = true
      http.post('/admin/agent/index', {
        ...params,
        ...route.query,
        ...page
      }).then(result => {
        tableJSON.value.datas = result.data
        tableJSON.value.page.total = result.total || Infinity
        tableJSON.value.page.page = result.current_page
        tableJSON.value.page.page_size = result.per_page
      }).finally(() => {
        tableJSON.value.loading = false
      })
    } else {
      // tableJSON.value.datas = []
      // tableJSON.value.page.total = 0
    }
  },
  extras: [
    {
      name: '添加',
      props: { type: 'primary', plain: true, },
      show: () => powerlimits.includes('dlslb_tj'),
      action: () => {
        tableJSON.value.drawer.show = true
        tableJSON.value.drawer.title = '添加代理商'
        getOptData()
      }
    }
  ],
  drawer: reactive({
    show: false,
    title: '',
    size: '70%',
    close: () => {
      cancelDrawer()
    }
  })
})

// 修改、查看
const formLoading = ref(false)
const getAgentDetail = (id) => {
  formLoading.value = true
  http.post('/admin/agent/view', {id: id}).then(res => {
    if(res) {
      const row = JSON.parse(JSON.stringify(res))
      row.push_agent_id = !row.push_agent_id ? '' : row.push_agent_id
      row.push_agent_gift_id = !row.push_agent_gift_id ? '' : row.push_agent_gift_id
      row.goods_price.forEach(i => {
        i.price /= 100
        i.cost_price /= 100
        i.fee_price /= 100
      })
      // row.cash_user_id = row.cash_user_id || 1
      // row.cash_user_type = row.cash_user_type || 1
      // row.cash_user_id = row.cash_user_id || ''
      agentForm.value = row
    }
  }).finally(() => {
    formLoading.value = false
  })
}

const cancelDrawer = () => {
  agentFormType.value = ''
  delete agentForm.value.id
  agentFormEle.value.resetFields()
  
  agentForm.value.push_agent_id = ''
  agentForm.value.push_agent_gift_id = ''
  agentForm.value.goods_price = []
  agentForm.value.cash_user_id = ''
  agentForm.value.cash_user_info = null
  agentForm.value.cash_type = 1
  agentForm.value.freeze_day = 1
  tableJSON.value.drawer.show = false
  agentFormEle.value.clearValidate('cash_user_id')
}
const handlerAddGoods = () => {
  const lastData = agentForm.value.goods_price[agentForm.value.goods_price.length-1]
  if(lastData && (!lastData.goods_id || lastData.price === undefined || lastData.cost_price === undefined || lastData.fee_price === undefined)) {
    ElMessage.error('请完善商品信息数据')
  } else {
    agentForm.value.goods_price.push({
      goods_id: '',
      price: 0.01,
      cost_price: 0,
      fee_price: 0
    })
  }
}
const handlerDeleteGoods = (index) => {
  agentForm.value.goods_price.splice(index, 1)
}

const submitLoading = ref(false)
const handlerStoreGoods = () => {
  agentFormEle.value.validate((valid) => {
    if(valid) {
      submitLoading.value = true
      const lastData = agentForm.value.goods_price[agentForm.value.goods_price.length-1]
      if(lastData && (!lastData.goods_id || lastData.price === undefined || lastData.cost_price === undefined || lastData.fee_price === undefined)) {
        agentForm.value.goods_price = []
      }
      
      const formData = JSON.parse(JSON.stringify(agentForm.value))
      formData.goods_price.forEach(i => {
        i.price *= 100
        i.cost_price *= 100
        i.fee_price *= 100
      })
      // 提现账户为个人
      if(formData.cash_user_type === 2) {
        formData.cash_user_id = formData.cash_user_info.id
      }
      if(formData.cash_user_info !== undefined) delete formData.cash_user_info
      
      http.post(agentForm.value.id ? '/admin/agent/edit' : '/admin/agent/add', {
        ...formData
      }).then(res => {
        if(typeof res === 'boolean' && res) {
          ElMessage.success(agentForm.value.id ? '修改成功' : '添加成功')
          cancelDrawer()
          tableJSON.value.action(tableJSON.value.filter)
        }
      }).finally(() => {
        submitLoading.value = false
      })
    }
  })
}
</script>

<style lang="scss" scoped>
.add-form{
  padding-right: 10px;
  :deep(.el-select),
  :deep(.el-cascader){
    width: 100%;
  }
}
.tit{
  padding: 5px 0 15px;
}
.goods-info{
  display: flex;
  flex-direction: column;
  width: 100%;
  .btn-line{
    margin-bottom: 10px;
  }
  .info-list-hd{
    display: flex;
    flex-direction: row;
    //align-items: center;
    justify-content: flex-start;
    width: 100%;
    .red{
      color: var(--el-color-danger);
    }
  }
  :deep(.el-input-number){
    width: 100%;
    .el-input-number__decrease,
    .el-input-number__increase{
      display: none;
    }
    &.is-controls-right .el-input__inner{
      text-align: left;
    }
    .el-input__wrapper{
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
</style>
