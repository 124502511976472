<template>
  <div class="withdraw">
    <div class="withdraw-title">
      <h4>提现</h4>
    </div>
    <el-form-item
      label="提现方式"
      :rules="{required: true, message: '请选择提现方式'}"
      prop="cash_type"
    >
      <el-radio-group v-model="agentInfo.cash_type" disabled>
        <el-radio :label="1">微信</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item
      label="冻结期限"
      :rules="{required: true, message: '请输入冻结期限'}"
      prop="freeze_day"
    >
      <div>
        订单付款成功，
        <el-input-number v-model="agentInfo.freeze_day" :min="1" :max="25" controls-position="right" />
        天后自动到账（最大25天）
      </div>
    </el-form-item>
    <el-form-item
      label="提现账户"
      :rules="{required: true, message: '请选择提现账户'}"
      prop="cash_user_type"
    >
      <el-radio-group v-model="agentInfo.cash_user_type" @change="cashUserTypeChange">
        <el-radio :label="1">商户号</el-radio>
        <el-radio :label="2">个人微信</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item
      v-if="agentInfo.cash_user_type === 1"
      label="商户号"
      :rules="{required: true, message: '请输入商户号'}"
      prop="cash_user_id"
    >
      <el-input v-model.trim="agentInfo.cash_user_id" placeholder="请输入" />
    </el-form-item>
    <el-form-item
      v-else
      label="个人微信号"
      :rules="{required: true, message: '请选择个人微信号'}"
    >
      <div class="flex-col">
        <div v-if="(agentInfo.cash_user_info && typeof agentInfo.cash_user_info === 'object') && agentInfo.cash_user_info.id" class="val-wechat flex-col">
          <span>用户ID：{{ agentInfo.cash_user_info.id }}</span>
          <span>用户昵称：{{ agentInfo.cash_user_info.nickname }}</span>
          <span>授权手机号：{{ agentInfo.cash_user_info.phone }}</span>
        </div>
        <el-button
          v-if="powerlimits.includes('dlslb_txwx')"
          type="primary"
          plain
          @click="handlerOpenDialog"
        >编辑</el-button>
      </div>
    </el-form-item>
    
    <el-dialog
      title="请选择提现微信"
      append-to-body
      v-model="dialogVisible"
      width="800px"
      @close="handlerCloseDialog"
    >
      <div class="dialog-bd">
        <div class="hd-form">
          <el-input v-model="tableParam.mobile" placeholder="请输入手机号" clearable />
          <el-button class="btn" type="primary" @click="getTableData">查询</el-button>
        </div>
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          max-height="500px"
          border
        >
          <template v-for="col in tableColumns" :key="col.prop">
            <el-table-column :prop="col.prop" :label="col.label" />
          </template>
          <el-table-column label="操作" porp="act" fixd="right" width="90">
            <template #default="{row}">
              <el-button
                v-if="cashUserInfo.id === row.id"
                type="success"
                :icon="Check"
                @click="resetCashUserInfo"
              />
              <el-button
                v-else
                type="primary"
                @click="setCashUserInfo(row)"
              >选择</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="display: flex;flex-direction: row;justify-content: flex-end; margin-top: 20px;">
          <el-pagination
            v-model:page-size="tableParam.page_size"
            v-model:current-page="tableParam.page"
            :total="tableTotal"
            background
            @currentChange="getTableData"
          ></el-pagination>
        </div>
      </div>
      <template #footer>
        <el-button @click="handlerCloseDialog">取消</el-button>
        <el-button type="primary" @click="submitDialog">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
// 提现
import {ref, computed, reactive, onMounted, watch} from 'vue'
import {http, tool} from "@/assets/js"
import {useRoute} from 'vue-router'
import {Check} from '@element-plus/icons-vue'

const route = useRoute()
// 操作权限
const powerlimits = reactive(tool.getArrayToOneChat(route.meta.roles,'component'))

const props = defineProps({
  info: {
    type: Object,
  }
})
const emits = defineEmits([
  'update:info',
  'submit'
])
const cashUserInfo = ref({
  id: '',
  nickname: '',
  phone: ''
})

const agentInfo = computed({
  get(){
    return props.info
  },
  set(val){
    emits('update:info', val)
  }
})

const dialogVisible = ref(false)
const tableData = ref([])
const tableColumns = ref([
  {
    label: '用户ID',
    prop: 'id'
  },
  {
    label: '用户昵称',
    prop: 'nickname'
  },
  {
    label: '授权手机号',
    prop: 'mobile'
  },
])
const tableLoading = ref(false)
const tableParam = ref({
  mobile: '',
  page: 1,
  page_size: 10,
})
const tableTotal = ref(0)
const getTableData = () => {
  tableLoading.value = true
  http.post(`admin/user/index`, tableParam.value).then(res => {
    tableData.value = res.data
    tableTotal.value = res.total
  }).finally(() => {
    tableLoading.value = false
  })
}
const handlerOpenDialog = () => {
  dialogVisible.value = true
  if(agentInfo.value.cash_user_info){
    cashUserInfo.value.id = agentInfo.value.cash_user_info.id
    cashUserInfo.value.nickname = agentInfo.value.cash_user_info.nickname
    cashUserInfo.value.phone = agentInfo.value.cash_user_info.phone
  } else {
    cashUserInfo.value = {
      id: '',
      nickname: '',
      phone: ''
    }
  }
  getTableData()
}

const handlerCloseDialog = () => {
  dialogVisible.value = false
  tableParam.value = {
    mobile: '',
    page: 1,
    page_size: 10,
  }
}


const cashUserTypeChange = (e) => {
  if(e === 2) {
    agentInfo.value.cash_user_info = null
  }
  agentInfo.value.cash_user_id = ''
}
const setCashUserInfo = (row) => {
  cashUserInfo.value = {
    id: row.id,
    nickname: row.nickname,
    phone: row.phone
  }
}
const resetCashUserInfo = () => {
  cashUserInfo.value = {
    id: '',
    nickname: '',
    phone: ''
  }
}
const submitDialog = () => {
  agentInfo.value.cash_user_info = {
    id: cashUserInfo.value.id,
    phone: cashUserInfo.value.phone,
    nickname: cashUserInfo.value.nickname
  }
  agentInfo.value.cash_user_id = agentInfo.value.cash_user_info.id
  dialogVisible.value = false
}
</script>

<style scoped lang="scss">
.flex-col{
  display: flex;
  flex-direction: column;
}
.flex-row{
  display: flex;
  flex-direction: row;
}
.withdraw{
  &-title{
    padding: 5px 0 15px;
  }
  .val-wechat{
    padding: 5px 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid var(--el-border-color);
    background-color: #f7f8f7f8;
  }
}
.dialog-bd{
  @extend .flex-col;
  .hd-form{
    @extend .flex-row;
    margin-bottom: 15px;
    .btn{
      margin-left: 10px;
    }
  }
}
</style>
